.education-container {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    /* padding: 1rem; */
    gap: 1rem;
  }
  
  .education-title {
    margin-top: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 1.5rem;
    background-color: #f7f7f7;
  }
  
  .education-container a {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .education-container a:hover {
    text-decoration: underline;
  }
  