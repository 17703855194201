.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px; /* Adjust based on preference */
    padding: 20px; /* Adjust based on preference */
  }
  
  .photo img {
    width: 100%; /* Make images fully responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Optional: adds rounded corners to your images */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */
  }
  
  @media (max-width: 768px) {
    .photo-gallery {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust for smaller screens */
    }
  }
  