/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Font families */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Font sizes */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

/* Color scheme */
:root {
  --primary-color: #003f5c;
  --secondary-color: #00a8e8;
  --text-color: #333;
  --light-bg-color: #f7f7f7;
  --dark-bg-color: #222;
}